import { useQueries, useQuery } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';

const baseEndpoint = '/errors';

export interface PublishedErrorList {
  publishedErrors: PublishedError[];
  totalCount: number;
}

export type SourceType =
  // Rating Config Import
  | 'urn:flux:rating-config:csv-plan-importer'
  | 'urn:flux:rating-config:generic-plan-importer'
  | 'urn:flux:rating-config:plan-importer'
  | 'urn:flux:rating-config:rates-update-importer'
  | 'urn:flux:rating-config:plan-link-importer'
  | 'urn:flux:rating-config:plan-migration'
  // Rating Config Provisioning
  | 'urn:flux:rating-config:connection-updated'
  | 'urn:flux:rating-config:provision-energy-plan'
  | 'urn:flux:rating-config:supply-agreement-cancelled'
  | 'urn:flux:rating-config:supply-agreement-updated'
  // Telemetry
  | 'urn:flux:telemetry:file-importer'
  | 'urn:flux:telemetry:telemetry-data-manager'
  | 'urn:flux:msats:meter-data'
  | 'urn:flux:telemetry:telemetry-calculator'
  // Billing
  | 'urn:flux:billing'
  // Rating Calculator
  | 'urn:flux:rating-calculator:connection-config-sync'
  | 'urn:flux:rating-calculator:data-pipeline-result-receiver'
  | 'urn:flux:rating-calculator:plan-sync'
  | 'urn:flux:rating-calculator:cumulative-data-sync'
  | 'urn:flux:rating-calculator:telemetry-data-sync'
  | ':exception';

export interface PublishedError {
  _publishedAt: string;
  _type: string;
  _version: string;
  attributes: ErrorAttribute[];
  _correlationId?: string;
  href?: string;
  message?: string;
  source: string;
  urn: SourceType;
}

interface ErrorAttribute {
  attribute: string;
  message?: string;
  urn: string;
}

export type UrnFilterType = 'INCLUDE' | 'EXCLUDE';

interface ErrorsParams {
  fromDate: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  urnFilterType?: UrnFilterType;
  urns?: SourceType[];
  toDate: string;
  showSystemErrors?: boolean;
}

export const useErrors = (params: ErrorsParams) => {
  const { fromDate, limit, offset, sortBy, urnFilterType, urns, toDate, showSystemErrors } = params;

  return useQuery(
    ['errors', fromDate, limit, offset, sortBy, urnFilterType, urns, toDate, showSystemErrors],
    async () => errorFn(params)
  );
};

export const useParallelErrors = (paramsArray: ErrorsParams[]) => {
  return useQueries({
    queries: paramsArray.map((params) => {
      const { fromDate, limit, offset, sortBy, urnFilterType, urns, toDate, showSystemErrors } =
        params;

      return {
        queryKey: [
          'errors',
          fromDate,
          limit,
          offset,
          sortBy,
          urnFilterType,
          urns,
          toDate,
          showSystemErrors,
        ],
        queryFn: async () => errorFn(params),
      };
    }),
  });
};

const errorFn = (params: ErrorsParams) => {
  const search = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      search.set(key, `${value}`);
    }
  });

  return apiClient().get(`${baseEndpoint}?${search.toString()}`);
};
