import React, { FunctionComponent } from 'react';
import { ErrorModel } from '../../../../api/ErrorDisplay';
import PlanLinkForm from './PlanLinkForm';
import {
  PlanLinkBody,
  SupplyPeriodDetails,
  usePlanLinkCreation,
  usePlanLinkCreationV3,
} from '../../connectionsApi';
import { overlaps } from '../../../../util/helper-func';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface AddExistingPlanProps {
  close: () => void;
  connectionId: string;
  supplyPeriodId: string;
  supplyPeriodEndDate: string;
  supplyPeriodStartDate: string;
  supplyPeriodDetails: SupplyPeriodDetails;
}

const AddExistingPlan: FunctionComponent<AddExistingPlanProps> = ({
  close,
  connectionId,
  supplyPeriodId,
  supplyPeriodEndDate,
  supplyPeriodStartDate,
  supplyPeriodDetails,
}) => {
  const { lobi796ShowPlanLinkPurposeOptions } = useFlags();
  const {
    mutateAsync,
    isLoading: isSubmitting,
    error,
  } = lobi796ShowPlanLinkPurposeOptions
    ? usePlanLinkCreationV3(supplyPeriodId)
    : usePlanLinkCreation(connectionId, supplyPeriodId);

  const addPlanToSupplyPeriod = async (
    pricingPlanId: string,
    startDate: string,
    endDate: string,
    purpose: string
  ) => {
    // check if this planlink already exists and has an overlapping time period
    const hasOverlap =
      supplyPeriodDetails.plans
        .filter((e) => e.pricingPlanId === pricingPlanId)
        .filter((e) =>
          overlaps(
            new Date(e.startDate),
            new Date(e.endDate),
            new Date(startDate),
            new Date(endDate)
          )
        ).length !== 0;

    // confirm
    let process = true;
    if (hasOverlap) {
      process = window.confirm(
        'This plan link date range overlaps the same plan link for the supply period, do you want to update the existing plan link date range?'
      );
    }

    if (process) {
      const newPlanLink: PlanLinkBody = {
        pricingPlanId,
        startDate,
        endDate,
        purpose,
      };

      const result = await mutateAsync(newPlanLink);

      if (result) {
        close();
      }
    }
  };

  return (
    <div className="apl-form-layout-v1" data-testid="add-plan-form">
      <h2>Add existing plan</h2>

      <PlanLinkForm
        close={close}
        error={error as ErrorModel}
        isUpdating={isSubmitting}
        submitFunction={addPlanToSupplyPeriod}
        supplyPeriodStartDate={supplyPeriodStartDate}
        supplyPeriodEndDate={supplyPeriodEndDate}
      />
    </div>
  );
};

export default AddExistingPlan;
