import React, { FunctionComponent, useState } from 'react';
import { format, isAfter, isBefore, isDate, isEqual, max, min } from 'date-fns';
import { ErrorMessage, Field, Form, Formik, useField, useFormikContext } from 'formik';
import { isNil } from 'ramda';
import Select, { components } from 'react-select';
import { date, object, string } from 'yup';
import ErrorDisplay, { ErrorModel } from '../../../../api/ErrorDisplay';
import { Permission } from '../../../../auth/getPermissions';
import searchIcon from '../../../../icons/search.svg';
import { alphabeticalSortByObjectProp } from '../../../../util/helper-func';
import AuthorizedAction from '../../../common/AuthorizedAction';
import { FormRow, typeaheadStyles } from '../../../form';
import FluxDatePicker from '../../../form/DatePicker';
import FieldWrapper, { FieldWidth } from '../../../form/FieldWrapper';
import Alert, { AlertEnum } from '../../../layout/Alert';
import { ErrorText } from '../../../layout/Errors';
import { usePlanSummary } from '../../../providers/planSummaryApi';
import { Provider, useProviders } from '../../../providers/providersApi';
import { SupplyPeriodPlanSummary } from '../../connectionsApi';
import { PlanSummary } from '../../../../api/openapi/rating-config';
import getConfig from '../../../../config/getConfig';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useListPlanLinkPurposes } from 'components/plans/planApi';
import { PlanLinkPurpose } from 'api/openapi/rating-config/models/PlanLinkPurpose';

interface PlanLinkFormProps {
  planLink?: SupplyPeriodPlanSummary;
  existingPlan?: PlanSummary;
  isUpdating: boolean;
  close: () => void;
  supplyPeriodEndDate: string;
  supplyPeriodStartDate: string;
  submitFunction: (
    pricingPlanId: string,
    startDate: string,
    endDate: string,
    purpose: string
  ) => Promise<any>;
  error?: ErrorModel;
}

const PlanLinkForm: FunctionComponent<PlanLinkFormProps> = ({
  planLink,
  existingPlan,
  close,
  error,
  isUpdating,
  supplyPeriodEndDate,
  supplyPeriodStartDate,
  submitFunction,
}) => {
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [plan, setPlan] = useState<PlanSummary | null>(existingPlan ? existingPlan : null);
  const { data: purposes } = useListPlanLinkPurposes();

  const isEdit = !isNil(planLink);

  const handleProviderChange = (id: string) => {
    setSelectedProviderId(id);
  };
  const { lobi796ShowPlanLinkPurposeOptions } = useFlags();
  // we need a dynamic yup validation schema as we want to check the start and
  // end dates fall within the current supply period and also the selected plan
  const planLinkSchema = object().shape({
    providerId: string().label('Provider').required('Provider is required'),
    pricingPlanId: string().label('Plan').required('Plan is required'),
    purpose: string().label('Purpose').required('Purpose is required'),
    startDate: date()
      .typeError('Must be a valid date')
      .label('Start date')
      .min(
        supplyPeriodStartDate,
        `Start date must be after ${format(new Date(supplyPeriodStartDate), 'dd/MM/yyyy')}`
      )
      .max(
        supplyPeriodEndDate ? supplyPeriodEndDate : new Date('9999-12-31'),
        supplyPeriodEndDate
          ? `Must be before ${format(new Date(supplyPeriodEndDate), 'dd/MM/yyyy')}`
          : 'Must be before 31/12/9999'
      )
      .when('pricingPlanId', (pricingPlanId: string, schema: any) => {
        if (pricingPlanId && plan?.planStart) {
          const planStartDate = new Date(plan.planStart);
          const minDate = max([planStartDate, new Date(supplyPeriodStartDate)]);
          const minDateNormalized = new Date(minDate);
          minDateNormalized.setHours(0);

          return schema.min(
            minDateNormalized,
            `Start date must be after ${format(minDate, 'dd/MM/yyyy')}`
          );
        }

        return schema;
      })
      .required(),
    endDate: date()
      .typeError('Must be a valid date')
      .label('End date')
      .when('startDate', (startDate: Date, schema: any) => {
        if (isDate(startDate) && isBefore(startDate, new Date('9999-12-31'))) {
          return schema.min(startDate, 'End date must be after start date');
        } else {
          return schema;
        }
      })
      .when('pricingPlanId', (value, schema) => {
        if (supplyPeriodEndDate) {
          return schema.required(() => "Can't be open ended if supply period isn't");
        } else {
          return schema.nullable();
        }
      })
      .when('pricingPlanId', (value, schema) => {
        if (plan?.planEnd) {
          return schema.required(() => "Can't be open ended if plan isn't");
        } else {
          return schema.nullable();
        }
      })
      .when('pricingPlanId', (pricingPlanId: string, schema: any) => {
        if (pricingPlanId && plan?.planEnd) {
          const planEndDate = new Date(plan.planEnd);
          const supplyEndDate = supplyPeriodEndDate
            ? new Date(supplyPeriodEndDate)
            : new Date('9999-12-31');

          const maxDate = min([planEndDate, supplyEndDate]);
          const maxDateNormalized = new Date(maxDate);
          maxDateNormalized.setHours(0);

          return schema.max(
            maxDateNormalized,
            `End date must be before ${format(maxDate, 'dd/MM/yyyy')}`
          );
        }
        return schema;
      })
      .max(
        supplyPeriodEndDate ? supplyPeriodEndDate : new Date('9999-12-31'),
        supplyPeriodEndDate
          ? `End date must be before ${format(new Date(supplyPeriodEndDate), 'dd/MM/yyyy')}`
          : 'End date must be before 31/12/9999'
      )
      .transform((curVal, origVal) => (origVal === 'null' ? null : curVal)),
  });

  return (
    <Formik
      initialValues={{
        providerId: isEdit ? `${planLink?.providerId}` : '',
        pricingPlanId: isEdit ? `${planLink?.pricingPlanId}` : '',
        startDate: isEdit ? `${planLink?.startDate}` : '',
        endDate: isEdit ? `${planLink?.endDate}` : '',
        purpose: isEdit ? `${planLink?.purpose}` : 'urn:flux:rating:purpose:billable',
      }}
      validationSchema={planLinkSchema}
      onSubmit={({ pricingPlanId, startDate, endDate, purpose }) => {
        // need to convert null dates to empty strings to comply with the API
        // and this is required due to a limitation of the yup library not
        // allowing an empty string if it is meant to be a date()
        const getStartDate = startDate !== 'null' ? startDate : '';
        const getEndDate = endDate !== 'null' ? endDate : '';
        console.log(purpose);
        submitFunction(pricingPlanId, getStartDate, getEndDate, purpose);
      }}
    >
      {({ dirty, isValid }) => (
        <Form className="apl-form-layout-v1">
          {error && (
            <Alert type={AlertEnum.DANGER}>
              {isEdit ? 'Could not update plan dates' : 'Could not add plan'}
            </Alert>
          )}
          <FormRow>
            <ProvidersLookup planLink={planLink} isEdit={isEdit} onChange={handleProviderChange} />
          </FormRow>
          <FormRow>
            <PlansLookupWrapper
              isEdit={isEdit}
              planLink={planLink}
              selectedProviderId={selectedProviderId}
              supplyPeriodEndDate={supplyPeriodEndDate}
              supplyPeriodStartDate={supplyPeriodStartDate}
              onPlanSelect={setPlan}
            />
          </FormRow>
          <FormRow>
            <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="start-date" label="Valid from">
              <Field
                autoComplete="off"
                data-testid="start-date"
                name="startDate"
                id="start-date"
                component={FluxDatePicker}
              />
              <ErrorMessage component={ErrorText} name="startDate" />
            </FieldWrapper>
            <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="end-date" label="Valid to">
              <Field
                autoComplete="off"
                data-testid="end-date"
                name="endDate"
                id="end-date"
                component={FluxDatePicker}
              />
              <ErrorMessage component={ErrorText} name="endDate" />
            </FieldWrapper>
          </FormRow>
          {lobi796ShowPlanLinkPurposeOptions && purposes?.length > 1 && (
            <FormRow>
              <FieldWrapper fieldWidth={FieldWidth.HALF} htmlFor="purpose" label="Purpose">
                <Field
                  autoComplete="off"
                  data-testid="purpose"
                  name="purpose"
                  id="purpose"
                  as="select"
                  className="apl-select-v1_0"
                >
                  {purposes?.map((purpose: PlanLinkPurpose) => (
                    <option key={purpose.urn} value={purpose.urn}>
                      {purpose.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage component={ErrorText} name="purpose" />
              </FieldWrapper>
            </FormRow>
          )}
          <div className="apl-display-flex apl-justify-content-end">
            <button
              data-testid="cancel-button"
              className="apl-button-v1"
              onClick={close}
              disabled={isUpdating}
              type="button"
            >
              Cancel
            </button>
            <AuthorizedAction
              extraClasses="is-primary"
              isDisabled={isUpdating || !isValid || !dirty}
              permission={Permission.CONNECTION_EDIT}
              testId="add-plan-button"
              title={isUpdating || !isValid || !dirty ? '' : undefined}
              type="submit"
            >
              Save
            </AuthorizedAction>
          </div>
          <ErrorDisplay error={error} />
        </Form>
      )}
    </Formik>
  );
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <img src={searchIcon} />
  </components.DropdownIndicator>
);

interface ProvidersLookupProps {
  planLink?: SupplyPeriodPlanSummary;
  isEdit: boolean;
  onChange: (event: any) => void;
}

const ProvidersLookup: FunctionComponent<ProvidersLookupProps> = ({
  planLink,
  isEdit,
  onChange,
}) => {
  const { data: providers, isInitialLoading: isLoadingProviders } = useProviders();
  const { setFieldValue } = useFormikContext();

  if (isLoadingProviders) {
    return (
      <FieldWrapper
        className="apl-flex-grow-1"
        fieldWidth={FieldWidth.NONE}
        htmlFor="disabled-provider-field"
        label="Provider"
      >
        <Field
          disabled={true}
          autoComplete="off"
          data-testid="disabled-provider-field"
          name="providerId"
          id="disabled-provider-field"
          className="apl-select-v1_0"
          as="select"
        >
          <option value="" />
        </Field>
      </FieldWrapper>
    );
  }

  if (isEdit) {
    return (
      <FieldWrapper
        className="apl-flex-grow-1"
        fieldWidth={FieldWidth.NONE}
        htmlFor="provider-field"
        label="Provider"
      >
        <Field
          disabled={true}
          autoComplete="off"
          data-testid="provider-field"
          name="providerId"
          id="provider-field"
          className="apl-select-v1_0"
          as="select"
        >
          <option value={planLink?.providerId}>{planLink?.providerName}</option>
        </Field>
        <ErrorMessage component={ErrorText} name="providerId" />
      </FieldWrapper>
    );
  }

  const handleProviderChange = (value: any) => {
    const providerId = value.value;

    setFieldValue('providerId', providerId);
    onChange(providerId);
  };

  const options = (alphabeticalSortByObjectProp('name')(providers) as Provider[]).map(
    (p: Provider) => ({
      label: p.name,
      value: p.id,
    })
  );

  return (
    <FieldWrapper
      className="apl-flex-grow-1"
      fieldWidth={FieldWidth.NONE}
      htmlFor="provider-field"
      label="Provider"
    >
      <Select
        classNamePrefix="provider-field"
        components={{ DropdownIndicator }}
        filterOption={(option: { label: string }, input: string) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        formatOptionLabel={(data: { label: string }, meta: { inputValue: string }) => {
          const { inputValue } = meta;
          const pattern = new RegExp(inputValue, 'ig');

          return inputValue ? (
            <span
              dangerouslySetInnerHTML={{
                __html: data.label.replace(pattern, '<strong>$&</strong>'),
              }}
            />
          ) : (
            data.label
          );
        }}
        id="provider-field"
        name="providerId"
        noOptionsMessage={() => 'No results'}
        onChange={handleProviderChange}
        options={options}
        styles={typeaheadStyles}
      />
      <ErrorMessage component={ErrorText} name="providerId" />
    </FieldWrapper>
  );
};

interface PlansLookupProps {
  isEdit: boolean;
  planLink?: SupplyPeriodPlanSummary;
  onPlanSelect: (value: PlanSummary | null) => void;
  selectedProviderId: string;
  supplyPeriodEndDate: string;
  supplyPeriodStartDate: string;
}

const PlansLookupWrapper: FunctionComponent<PlansLookupProps> = ({
  isEdit,
  planLink,
  onPlanSelect,
  selectedProviderId,
  supplyPeriodEndDate,
  supplyPeriodStartDate,
}) => {
  if (isEdit) {
    return (
      <FieldWrapper
        className="apl-flex-grow-1"
        htmlFor="plan-field"
        label="Plan"
        fieldWidth={FieldWidth.NONE}
      >
        <Field
          disabled={true}
          autoComplete="off"
          data-testid="plan-field"
          name="pricingPlanId"
          id="plan-field"
          className="apl-select-v1_0 "
          as="select"
        >
          <option value={planLink?.pricingPlanId}>{planLink?.planName}</option>
        </Field>
        <ErrorMessage component={ErrorText} name="pricingPlanId" />
      </FieldWrapper>
    );
  } else {
    return selectedProviderId ? (
      <PlansLookup
        isEdit={isEdit}
        onPlanSelect={onPlanSelect}
        selectedProviderId={selectedProviderId}
        supplyPeriodEndDate={supplyPeriodEndDate}
        supplyPeriodStartDate={supplyPeriodStartDate}
      />
    ) : (
      <DisabledPlansLookup />
    );
  }
};

const PlansLookup: FunctionComponent<PlansLookupProps> = ({
  onPlanSelect,
  selectedProviderId,
  supplyPeriodEndDate,
  supplyPeriodStartDate,
}) => {
  const [, , helpers] = useField({ name: 'pricingPlanId' });
  const { data: plans, isInitialLoading: isLoading } = usePlanSummary(selectedProviderId, {
    enabled: !!selectedProviderId,
  });

  const { mngr102PoaShareability } = useFlags();
  const { shareablePlans } = getConfig();

  if (isLoading) {
    return <DisabledPlansLookup />;
  }

  const handleSelect = (value: any) => {
    const planId = value.value;
    const selected =
      (plans.planSummaries as PlanSummary[]).find((summary) => summary.id === planId) ?? null;

    // update parent form component with selected plan to extract start and end
    // dates to validate the plan link being created
    onPlanSelect(selected);

    helpers.setValue(planId);
  };

  const supplyStartDate = new Date(supplyPeriodStartDate);
  supplyStartDate.setHours(0);

  const supplyEndDate = supplyPeriodEndDate ? new Date(supplyPeriodEndDate) : '';

  if (supplyEndDate) {
    supplyEndDate.setHours(0);
  }

  const options =
    plans?.planSummaries?.length > 0
      ? (alphabeticalSortByObjectProp('planName')(plans.planSummaries) as PlanSummary[])
          // deprecated, archived and connection specific plans should not be available to select
          .filter(
            (p) =>
              p.planState !== 'DEPRECATED' &&
              p.planState !== 'ARCHIVED' &&
              (shareablePlans && mngr102PoaShareability
                ? !(p.planScope === 'CONNECTION_SPECIFIC' && p.shareable === false)
                : p.planScope !== 'CONNECTION_SPECIFIC')
          )
          .filter((p) => {
            // need to check if this plan start and end date intersects with
            // the current supply period dates
            const planStart = new Date(p.planStart ? p.planStart : '');
            planStart.setHours(0);

            const planEnd = p.planEnd ? new Date(p.planEnd) : null;
            if (planEnd) {
              planEnd.setHours(0);
            }

            return (
              (!supplyEndDate ||
                isBefore(planStart, supplyEndDate) ||
                isEqual(planStart, supplyEndDate)) &&
              (!planEnd || isAfter(planEnd, supplyStartDate) || isEqual(planEnd, supplyStartDate))
            );
          })
          .map((p: PlanSummary) => ({
            label: p.planName,
            value: p.id,
          }))
      : [];

  return (
    <FieldWrapper
      className="apl-flex-grow-1"
      htmlFor="plan-field"
      label="Plan"
      fieldWidth={FieldWidth.NONE}
    >
      <Select
        classNamePrefix="plan-field"
        components={{ DropdownIndicator }}
        filterOption={(option: { label: string }, input: string) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        formatOptionLabel={(data: { label: string }, meta: { inputValue: string }) => {
          const { inputValue } = meta;
          const pattern = new RegExp(inputValue, 'ig');

          return inputValue ? (
            <span
              dangerouslySetInnerHTML={{
                __html: data.label.replace(pattern, '<strong>$&</strong>'),
              }}
            />
          ) : (
            data.label
          );
        }}
        id="plan-field"
        name="pricingPlanId"
        noOptionsMessage={() => 'No results'}
        onChange={handleSelect}
        options={options ? options : ([] as any)}
        styles={typeaheadStyles}
      />
      <ErrorMessage component={ErrorText} name="pricingPlanId" />
    </FieldWrapper>
  );
};

const DisabledPlansLookup: FunctionComponent = () => {
  return (
    <FieldWrapper
      className="apl-flex-grow-1"
      htmlFor="disabled-plan-field"
      label="Plan"
      fieldWidth={FieldWidth.NONE}
    >
      <Field
        disabled={true}
        autoComplete="off"
        data-testid="disabled-plan-field"
        name="pricingPlanId"
        id="disabled-plan-field"
        className="apl-select-v1_0"
        as="select"
      >
        <option value="" />
      </Field>
    </FieldWrapper>
  );
};

export default PlanLinkForm;
