import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import React, { FunctionComponent } from 'react';
import { Connection, SupplyPeriodSummary, useUpdateSupplyPeriod } from '../connectionsApi';
import AuthorizedAction from '../../common/AuthorizedAction';
import Alert, { AlertEnum } from '../../layout/Alert';
import { ErrorText } from '../../layout/Errors';
import { Permission } from '../../../auth/getPermissions';
import FieldWrapper, { FieldWidth } from 'components/form/FieldWrapper';

interface ExpectedChargeComponentsFormProps {
  connection: Connection;
  supplyPeriod: SupplyPeriodSummary;
  onCancel: () => void;
  onSubmit: () => void;
}

const ExpectedChargeComponentsForm: FunctionComponent<ExpectedChargeComponentsFormProps> = ({
  connection,
  supplyPeriod,
  onCancel,
  onSubmit,
}) => {
  const allChargeTags = ['NETWORK', 'ENERGY', 'EQUIPMENT', 'LEVY', 'ENERGYFEE'];

  const { error, isLoading, mutateAsync } = useUpdateSupplyPeriod(connection.id, supplyPeriod.id, {
    enabled: !!connection?.id && !!supplyPeriod?.id,
  });

  const handleSubmit = async (data: FormikValues) => {
    const { expectedChargeTags } = data;

    const result = await mutateAsync({
      externalSupplyAgreementId: supplyPeriod.externalSupplyAgreementId,
      contractedParty: supplyPeriod.contractedParty,
      startDate: supplyPeriod.startDate,
      endDate: supplyPeriod.endDate,
      attributes: supplyPeriod.attributes,
      expectedChargeTags,
    });

    if (result) {
      onSubmit();
    }
  };

  return (
    <Formik
      initialValues={{
        expectedChargeTags: supplyPeriod.expectedChargeTags,
      }}
      onSubmit={(data) => handleSubmit(data)}
    >
      {({ dirty, isValid }) => (
        <Form className="apl-form-layout-v1">
          <>
            <h2>Edit charge components</h2>
            {error && <Alert type={AlertEnum.DANGER}>Sorry, there was an error</Alert>}
            <FieldWrapper
              fieldWidth={FieldWidth.FULL}
              htmlFor="expected-charge-tags"
              label="Expected charge components:"
            >
              {allChargeTags.map((tag) => (
                <div className="apl-checkbox-v1 apl-mb-xs" key={`charge-tag-field--${tag}`}>
                  <Field
                    className="apl-checkbox__input"
                    id={`expected-charge-tag--${tag.toLowerCase()}`}
                    name="expectedChargeTags"
                    type="checkbox"
                    value={tag}
                  />
                  <label
                    className="apl-checkbox__label"
                    htmlFor={`expected-charge-tag--${tag.toLowerCase()}`}
                  >
                    {tag === 'ENERGYFEE'
                      ? 'Energy fee'
                      : tag.charAt(0) + tag.slice(1).toLowerCase()}
                  </label>
                </div>
              ))}
              <ErrorMessage component={ErrorText} name="expectedChargeTags" />
            </FieldWrapper>
            <ErrorMessage component={ErrorText} name="expectedChargeTags" />
            <div className="apl-display-flex apl-justify-content-end">
              <button
                data-testid="cancel-button"
                className="apl-button-v1"
                onClick={() => onCancel()}
                disabled={isLoading}
                type="button"
              >
                Cancel
              </button>
              <AuthorizedAction
                extraClasses="is-primary"
                isDisabled={isLoading || !isValid || !dirty}
                permission={Permission.CONNECTION_EDIT}
                testId="save-connection-button"
                type="submit"
              >
                Save
              </AuthorizedAction>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default ExpectedChargeComponentsForm;
