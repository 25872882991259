import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { Connection, SupplyPeriodSummary } from '../connectionsApi';
import AuthorizedAction from '../../common/AuthorizedAction';
import { Widget } from '../../dashboard/Dashboard';
import { modalStyles } from '../../layout/Modal';
import { Permission } from '../../../auth/getPermissions';
import ExpectedChargeComponentsForm from './ExpectedChargeComponentsForm';

interface ExpectedChargeComponentsProps {
  connection: Connection;
  supplyPeriod?: SupplyPeriodSummary;
}

const ExpectedChargeComponents: FunctionComponent<ExpectedChargeComponentsProps> = ({
  connection,
  supplyPeriod,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  if (!supplyPeriod) {
    // no point in continuing without supply period but make it pretty
    return (
      <>
        <Widget
          className="apl-mt-none"
          headerComponent={
            <h3 className="apl-h3 widget__title widget__title--inline apl-mb-none">
              Expected charge components
            </h3>
          }
          width="full"
        >
          <p className="widget__list-item">Missing Supply Period</p>
        </Widget>
      </>
    );
  }

  const allChargeTags = supplyPeriod.expectedChargeTags;

  return (
    <>
      <Widget
        className="apl-mt-none"
        headerComponent={
          <h3 className="apl-h3 widget__title widget__title--inline apl-mb-none">
            Expected charge components
            <AuthorizedAction
              extraClasses="apl-ml-l"
              onClick={() => setModalOpen(true)}
              permission={Permission.CONNECTION_EDIT}
            >
              Edit
            </AuthorizedAction>
          </h3>
        }
        width="full"
      >
        <ul className="widget__list">
          {allChargeTags?.map((tag) => (
            <li className="widget__list-item" key={`expected-charge-tag--${tag.toLowerCase()}`}>
              {tag === 'ENERGYFEE'
                ? 'Energy fee'
                : tag.substr(0, 1) + tag.substring(1).toLowerCase()}
            </li>
          ))}
        </ul>
      </Widget>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
      >
        <ExpectedChargeComponentsForm
          connection={connection}
          supplyPeriod={supplyPeriod}
          onSubmit={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ExpectedChargeComponents;
