import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import UpdatePlan from './UpdatePlan';
import { planScopeDisplayConversion, planStateDisplayConversion } from './planApi';
import { modalStyles } from '../layout/Modal';
import { FieldOption, formatDateForDisplay } from '../../util/helper-func';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useListData } from '../../list-data/ListDataContext';
import { Plan } from '../../api/openapi/rating-config';
import getConfig from '../../config/getConfig';
import { EuiComboBox, EuiPanel } from '@elastic/eui';
import { PlanTagReference } from 'api/openapi/rating-config/models/PlanTagReference';

interface SimplePlanHeaderProps {
  plan: Plan | undefined;
  planId: string | undefined;
}

const PlanHeader: FunctionComponent<SimplePlanHeaderProps> = ({ plan, planId }) => {
  const {
    oci710PlanJurisdictions, // LD client key for oci-710-plan-jurisdictions
    mngr102PoaShareability, // LD client key is mngr-102-poa-shareability
    lobi515AddPlanTagsToUi, // LD client key is lobi-515-add-plan-tags-to-ui
  } = useFlags();
  const { shareablePlans } = getConfig();
  const [editPlanIsOpen, setEditPlanIsOpen] = useState(false);
  const closeEditPlan = () => setEditPlanIsOpen(false);

  const [marketfunction] = useListData(['MARKET_FUNCTION']);
  const jurisdictions = marketfunction?.data[
    'urn:flux:rating-config:market:jurisdictions'
  ] as FieldOption[];

  const resolveJurisdiction = (label: string) => {
    const match = jurisdictions?.find((it) => it.label === label)?.label || '';
    return match ? { label: 'Jurisdiction', value: match } : null;
  };

  const isConnectionSpecificPlan = plan?.planScope === 'CONNECTION_SPECIFIC';
  const jurisdiction = plan?.jurisdiction ? resolveJurisdiction(plan.jurisdiction) : null;

  let content: { label: string; value: string | JSX.Element }[] = [
    {
      label: 'Provider',
      value: plan?.providerName || '',
    },
    {
      label: 'Provider reference',
      value: plan?.providerReference || '',
    },
    {
      label: 'Start date',
      value: formatDateForDisplay(plan?.startDate),
    },
    {
      label: 'End date',
      value: formatDateForDisplay(plan?.endDate),
    },
    {
      label: 'Plan scope',
      value: planScopeDisplayConversion(plan?.planScope),
    },
    {
      label: 'Plan status',
      value: planStateDisplayConversion(plan?.planState),
    },
    {
      label: 'Timezone',
      value: plan?.timezone || '',
    },
    shareablePlans &&
      mngr102PoaShareability &&
      isConnectionSpecificPlan && {
        label: 'Shareable',
        value: (plan?.shareable === true ? 'Yes' : 'No') || 'Yes',
      },
    lobi515AddPlanTagsToUi && {
      label: 'Plan Tags',
      value: (
        <EuiComboBox
          isDisabled
          selectedOptions={plan?.tags?.map((tag: PlanTagReference) => {
            return { label: tag.tag, value: tag.tag };
          })}
        ></EuiComboBox>
      ),
    },
  ];

  if (oci710PlanJurisdictions && jurisdiction) {
    content = content.slice(0, 2).concat(jurisdiction).concat(content.slice(2));
  }

  return (
    <>
      <EuiPanel>
        <div className="apl-p-s apl-width-full">
          {content.map(
            (row: { label: string; value: string | JSX.Element }) =>
              row && (
                <div
                  className=" apl-display-flex apl-flex-row"
                  style={{
                    width: '100%',
                  }}
                  key={row.label}
                >
                  <div className="apl-width-1/2  apl-display-flex apl-flex-row apl-justify-content-start apl-pb-s">
                    <label>{row.label}</label>
                  </div>
                  <div className="apl-width-1/2 apl-display-flex apl-flex-row apl-justify-content-start">
                    {typeof row.value == 'string' ? (
                      <strong
                        data-testid={`plan-header-${row.label.split(' ').join('-').toLowerCase()}`}
                      >
                        {row.value}
                      </strong>
                    ) : (
                      row.value
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      </EuiPanel>

      <Modal
        isOpen={editPlanIsOpen}
        onRequestClose={closeEditPlan}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
      >
        <UpdatePlan close={closeEditPlan} planId={planId} plan={plan} />
      </Modal>
    </>
  );
};

export default PlanHeader;
