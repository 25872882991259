const config: Config = require('config.json');

export interface Config {
  apiBasePath: string;
  reportApiBasePath: string;
  authUri: string;
  billingApiBasePath: string;
  clientId: string;
  presentationApiBasePath: string;
  coreUrl: string;
  lossFactorApiBasePath: string;
  ratingCalculatorApiBasePath: string;
  telemetryCalculatorApiBasePath: string;
  showDiscountVariations: boolean;
  redirectUri: string;
  scope: string;
  telemetryApiBasePath: string;
  useAuth: boolean;
  useRefreshToken: boolean;
  environment: string;
  ldUser: string;
  client: string;
  metricsBasePath: string;
  lossFactorKey?: string;
  shareablePlans: boolean;
  showErrorDownload: boolean;
  showSystemErrors: boolean;
  dayLightSaving: boolean,
  marketOffset: string,
  marketTimezone: string;
  bestOfferProviderIds: string;
  bestOfferChangeoverDay: string;
  showUiElements: string;
  showRatingCalculatorMetrics: boolean;
  showConnectionDetailScreenErrors: boolean;
  showPlansWithEndDateLast: boolean;
  showErrorResponseBodyError: boolean;
  showCalculationCountOnConnectionScreen: boolean;
}

const getConfig = () => {
  return config;
};

export default getConfig;
