import React, { FunctionComponent } from 'react';
import { ErrorModel } from 'api/ErrorDisplay';
import { useNavigate } from 'react-router-dom';
import { usePlanV2 } from '../../../plans/planApi';
import PlanForm from '../../../plans/PlanForm';
import { SupplyPeriodPlanSummary, useConnectionSpecificPlanCreation } from '../../connectionsApi';
import { Charge, PlanBody } from '../../../../api/openapi/rating-config';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface AddConnectionSpecificPlanProps {
  connectionId: string;
  supplyPeriodId: string;
  planLink: SupplyPeriodPlanSummary;
  timezone: string | undefined;
  close: () => void;
}

const AddConnectionSpecificPlan: FunctionComponent<AddConnectionSpecificPlanProps> = ({
  connectionId,
  supplyPeriodId,
  planLink,
  timezone,
  close,
}) => {
  const navigate = useNavigate();

  const { main4641Mngr85UsePostConnectionSpecificPlansV2 } = useFlags();

  const {
    mutateAsync,
    isLoading: isSubmitting,
    error,
  } = useConnectionSpecificPlanCreation(
    connectionId,
    supplyPeriodId,
    planLink.id,
    timezone,
    main4641Mngr85UsePostConnectionSpecificPlansV2 ? 2 : 1
  );

  const { isInitialLoading: isLoading, isError, data } = usePlanV2(planLink.pricingPlanId);

  if (isError) {
    return <p>Error loading plan</p>;
  }

  if (isLoading) {
    return <p>Loading plan...</p>;
  }

  const createPlan = async (plan: PlanBody) => {
    const charges: Charge[] = data?.charges
      ? [...data.charges].map((c) => ({ ...c, id: undefined }))
      : [];

    try {
      const { id } = await mutateAsync({
        ...plan,
        charges,
      });

      if (id) {
        navigate(`/connections/${connectionId}/plan/${id}`);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <div className="apl-form-layout-v1" data-testid="add-plan-form">
      <h2>Create connection specific pricing</h2>

      <PlanForm
        close={close}
        error={error as ErrorModel}
        isConnectionSpecificPlan={true}
        isUpdating={isSubmitting}
        plan={data}
        selectedProviderId={planLink.providerId}
        submitFunction={createPlan}
      />
    </div>
  );
};

export default AddConnectionSpecificPlan;
