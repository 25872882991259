import React, { ChangeEvent, useEffect, useState } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { sortByDate } from '../../../util/helper-func';
import {
  BillingHoldStatus,
  InvoiceFormat,
  useBillingConfigurationSupplyAgreement,
  useBillingConfigurationSupplyAgreementUpdate,
} from '../../billing/billingConfigurationApi';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useExternalBillingPresentation } from '../../core/coreExternalBillingApi';
import Card from '../../layout/Card';
import { SupplyPeriodStatusDisplayMap, SupplyPeriodSummary } from '../connectionsApi';
import './SupplyAgreementComponent.scss';
import { Link } from 'react-router-dom';
import apiClient from '../../../api/apiClient';
import { ErrorPage, LoadingPage } from '../../layout/Page';
import { useConfig } from '../../../config/ConfigContext';
import { useRatingCalculatorMetrics } from '../../rating-calculator/ratingsApi';
import CalculationsCountComponent from './calculations/CalculationsCountComponent';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import RatingEngineField from './PreferredRatingEngineComponent';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface BillingStatusFieldProps {
  id: string;
  onStatusUpdate: (data: Toast) => void;
}

// contracted party id is called owner

const BillingStatusField = ({ id, onStatusUpdate }: BillingStatusFieldProps) => {
  const { data, isError } = useBillingConfigurationSupplyAgreement(id, {
    enabled: !!id,
  });

  const [changingFormat, setChangingFormat] = useState(false);
  const config = useConfig();
  const showInvoiceFormat =
    config.showUiElements && /(^|, ?)invoiceFormat($|,)/.test(config.showUiElements);

  if (isError) {
    console.error(new Error(data));
  }

  const { mutateAsync, isLoading: mutateLoading } =
    useBillingConfigurationSupplyAgreementUpdate(id);

  const handleStatusChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    await handleChange(value, null);
  };
  const handleFormatChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    await handleChange(null, value);
  };

  const handleChange = async (statusValue: string | null, formatValue: string | null) => {
    if (statusValue) {
      // send back original data, without the _links and the updated holdStatus
      const result = await mutateAsync({
        ...data,
        holdStatus: statusValue as BillingHoldStatus,
        _links: undefined,
      });

      if (result && result.holdStatus === statusValue) {
        onStatusUpdate({
          title: 'Billing status updated.',
          color: 'success',
          id: 'BillingStatusUpdate',
        });
      } else {
        onStatusUpdate({
          text: 'Please try again.',
          title: 'Billing status failed to update.',
          color: 'danger',
          id: 'BillingStatusUpdate',
        });
      }
    }
    if (formatValue) {
      setChangingFormat(true);
      // send back original data, without the _links and the updated holdStatus
      const result = await mutateAsync({
        ...data,
        invoiceFormat: formatValue as InvoiceFormat,
        _links: undefined,
      });
      setChangingFormat(false);
      if (result && result.invoiceFormat === formatValue) {
        onStatusUpdate({
          title: 'Invoice format updated.',
          color: 'success',
          id: 'invoiceFormat',
        });
      } else {
        onStatusUpdate({
          text: 'Please try again.',
          title: 'Invoice format failed to update.',
          color: 'danger',
          id: 'InvoiceFormat',
        });
      }
    }
  };

  return (
    <div
      className={cx(
        'apl-display-flex',
        'apl-flex-row',
        'apl-align-items-center',
        'billing-status-field'
      )}
    >
      {data && (
        <>
          {showInvoiceFormat && (
            <div className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start">
              {' '}
              <label className="apl-field__label apl-mr" htmlFor="invoice-format-field">
                Invoice format
              </label>
              <div
                className={cx(
                  'invoice-format-field__container',
                  'apl-display-flex',
                  'apl-flex-row',
                  'apl-align-items-center'
                )}
              >
                {isError ? (
                  'Not available'
                ) : (
                  <select
                    className="apl-select-v1_0 apl-mr-s"
                    disabled={mutateLoading}
                    id="invoice-format-field"
                    onChange={handleFormatChange}
                    style={{ width: '100px' }}
                    value={data.invoiceFormat ? data.invoiceFormat : ''}
                  >
                    <option value=""></option>
                    <option value="urn:flux:billing:invoice_format:bundled">Bundled</option>
                    <option value="urn:flux:billing:invoice_format:unbundled">Un-bundled</option>
                  </select>
                )}
                {mutateLoading && changingFormat && <LoadingSpinner />}
              </div>
            </div> // END OF INVOICE FORMAT
          )}

          <div className="apl-field-v1 apl-display-flex apl-flex-column apl-align-items-start">
            <label className="apl-field__label apl-mr" htmlFor="billing-status-field">
              Billing status
            </label>
            <div
              className={cx(
                'billing-status-field__container',
                'apl-display-flex',
                'apl-flex-row',
                'apl-align-items-center',
                {
                  'billing-status-field__container--active':
                    data.holdStatus === 'urn:flux:billing:hold_status:no' && !isError,
                  'billing-status-field__container--on-hold':
                    data.holdStatus !== 'urn:flux:billing:hold_status:no' && !isError,
                }
              )}
            >
              {isError ? (
                'Not available'
              ) : (
                <select
                  className="apl-select-v1_0 apl-pl-l apl-mr-s"
                  disabled={mutateLoading}
                  id="billing-status-field"
                  onChange={handleStatusChange}
                  style={{ width: '150px' }}
                  value={data.holdStatus}
                >
                  <option value="urn:flux:billing:hold_status:no">Active</option>
                  <option value="urn:flux:billing:hold_status:on_hold">On hold</option>
                </select>
              )}
              {mutateLoading && !changingFormat && <LoadingSpinner />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface SupplyAgreementComponentProps {
  connectionId: string | undefined;
  currentPeriod?: SupplyPeriodSummary;
  onPeriodChange: (id: string) => void;
  onContractedPartyChange: (id: string) => void;
  onStatusUpdate: (data: Toast) => void;
  supplyPeriods: SupplyPeriodSummary[];
}

const SupplyAgreementComponent = ({
  connectionId,
  currentPeriod,
  onPeriodChange,
  onContractedPartyChange,
  onStatusUpdate,
  supplyPeriods,
}: SupplyAgreementComponentProps) => {
  const [supplyPeriodData, setSupplyPeriodData] = useState<SupplyPeriodSummary[]>(supplyPeriods);
  const [supplyPeriodLoading, setSupplyPeriodLoading] = useState(true);
  const [supplyPeriodError, setSupplyPeriodError] = useState(false);
  const { showCalculationCountOnConnectionScreen } = useConfig();
  const { dysn45RatingEngineConfig } = useFlags();

  const { data } = useExternalBillingPresentation(currentPeriod?.externalSupplyAgreementId ?? '', {
    enabled: !!currentPeriod && !!currentPeriod.externalSupplyAgreementId,
  });

  const {
    data: calculations,
    isError: isCalculationsError,
    isInitialLoading: isCalculationsLoading,
  } = useRatingCalculatorMetrics(connectionId);

  // load all contracted parties for the supply periods
  useEffect(() => {
    const loadSupplyPeriods = async () => {
      try {
        const supplyPeriodWith = await Promise.all(
          supplyPeriods.map(async (supplyPeriod: SupplyPeriodSummary) => {
            let supplyPeriodPresentation = null;
            if (supplyPeriod.externalSupplyAgreementId) {
              supplyPeriodPresentation = await apiClient('presentation').get(
                `/external-billing/v1/presentation/${supplyPeriod.externalSupplyAgreementId}`
              );
            }
            return {
              ...supplyPeriod,
              contractedParty: supplyPeriodPresentation?.parties?.owner?.name,
            };
          })
        );
        setSupplyPeriodData(supplyPeriodWith);
        setSupplyPeriodLoading(false);
      } catch (err) {
        console.error(err); // todo: needs a better error handling?
        setSupplyPeriodError(true);
      }
    };
    loadSupplyPeriods();
  }, [JSON.stringify(supplyPeriods)]);

  if (supplyPeriodError) {
    return <ErrorPage />;
  }

  if (supplyPeriodLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="apl-display-flex apl-flex-row">
      <Card
        className={cx(
          'apl-display-flex',
          'apl-flex-row',
          'apl-flex-grow-1',
          'apl-align-items-center'
        )}
      >
        <div
          className={cx(
            'apl-display-flex',
            'apl-flex-row',
            'apl-flex-grow-1',
            'apl-align-items-start'
          )}
        >
          {renderContractedParty(
            data,
            currentPeriod,
            onContractedPartyChange,
            supplyPeriodData,
            connectionId
          )}
          {renderSupplyPeriod(currentPeriod, onPeriodChange, supplyPeriods)}
          {renderSupplyAgreementStatus(currentPeriod)}
          {dysn45RatingEngineConfig && currentPeriod && (
            <RatingEngineField id={currentPeriod.id} onStatusUpdate={onStatusUpdate} />
          )}
        </div>
        <div className={cx('apl-display-flex', 'apl-flex-row')} style={{ minWidth: '325px' }}>
          {showCalculationCountOnConnectionScreen && (
            <CalculationsCountComponent
              calculations={calculations}
              isCalculationsError={isCalculationsError}
              isCalculationsLoading={isCalculationsLoading}
            />
          )}
          {currentPeriod && (
            <BillingStatusField
              id={currentPeriod.externalSupplyAgreementId}
              onStatusUpdate={onStatusUpdate}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

const renderContractedParty = (
  data: any,
  currentPeriod: SupplyPeriodSummary | undefined,
  onContractedPartyChange: (id: string) => void,
  supplyPeriods: SupplyPeriodSummary[],
  connectionId: string | undefined
) => {
  return (
    <div
      className={cx('apl-field-v1', 'apl-display-flex', 'apl-flex-column', 'apl-align-items-start')}
      style={{ marginRight: '50px' }}
    >
      <label className="apl-field__label apl-mr" htmlFor="contracted-party-field">
        Contracted party
      </label>
      {renderContractedPartyBody(
        data,
        currentPeriod,
        onContractedPartyChange,
        supplyPeriods,
        connectionId
      )}
    </div>
  );
};

const renderContractedPartyBody = (
  data: any,
  currentPeriod: SupplyPeriodSummary | undefined,
  onContractedPartyChange: (id: string) => void,
  supplyPeriods: SupplyPeriodSummary[],
  connectionId: string | undefined
) => {
  // creates a sorted, distinct contracted party list
  const sortedSupplyPeriods = supplyPeriods.sort(
    (spA: SupplyPeriodSummary, spB: SupplyPeriodSummary) =>
      sortByDate(spA.endDate, spB.endDate, false)
  );
  const distinctContractedParties = distinct(sortedSupplyPeriods, 'owner');

  if (distinctContractedParties.length <= 1) {
    let contractedPartyName = '-';
    if (data && data?.parties?.owner?.name) {
      contractedPartyName = data.parties.owner.name;
    } else if (currentPeriod) {
      contractedPartyName = currentPeriod.contractedParty;
    }

    return (
      <div className="apl-display-flex apl-align-content-between">
        <p className="apl-my-none apl-h3" id="contracted-party-field">
          {contractedPartyName}
        </p>
        <span style={{ marginLeft: '10px', color: 'grey' }}>View timeline</span>
      </div>
    );
  } else {
    return (
      <div>
        <select
          className="apl-select-v1_0"
          style={{ lineHeight: '1.25', fontWeight: '700', fontSize: '1.125rem' }}
          id="contracted-party-field"
          data-testid="contracted-party-field"
          onChange={(event) => onContractedPartyChange(event.target.value)}
          value={currentPeriod && currentPeriod.owner ? currentPeriod.owner : ''}
        >
          {distinctContractedParties.map((period) => {
            return (
              <option key={period.owner} value={period.owner} data-testid="contracted-party-option">
                {period.contractedParty}
              </option>
            );
          })}
        </select>
        <Link
          className="apl-color-primary"
          style={{ marginLeft: '10px' }}
          to={`/connections/${connectionId}`}
        >
          View timeline
        </Link>
      </div>
    );
  }
};

/**
 * Extract distinct values from the given array depending on the given property.
 * It will return the first item if there is more than one item with the same
 * property in the list. It retains the order of the original list.
 * @param list
 * @param distinctBy
 */
const distinct = (list: any[], distinctBy: string) => {
  const distinctList = [];
  const set = new Set();

  for (const item of list) {
    if (!set.has(item[distinctBy])) {
      set.add(item[distinctBy]);
      distinctList.push(item);
    }
  }
  return distinctList;
};

const renderSupplyAgreementStatus = (currentPeriod: SupplyPeriodSummary | undefined) => {
  return (
    <div
      className={cx('apl-field-v1', 'apl-display-flex', 'apl-flex-column', 'apl-align-items-start')}
      style={{ marginRight: '50px' }}
    >
      <label className="apl-field__label apl-mr" htmlFor="supply-agreement-status-field">
        Supply agreement status
      </label>

      <p className="apl-my-none" id="supply-agreement-status-field">
        {currentPeriod ? SupplyPeriodStatusDisplayMap[currentPeriod.status] : '-'}
      </p>
    </div>
  );
};

const renderSupplyPeriod = (
  currentPeriod: SupplyPeriodSummary | undefined,
  onPeriodChange: (id: string) => void,
  supplyPeriods: SupplyPeriodSummary[]
) => {
  return (
    <div
      className={cx('apl-field-v1', 'apl-display-flex', 'apl-flex-column', 'apl-align-items-start')}
      style={{ marginRight: '50px' }}
    >
      {supplyPeriods && (
        <>
          <label className="apl-field__label apl-mr" htmlFor="supply-period-field">
            Supply period
          </label>
          <select
            className="apl-select-v1_0"
            id="supply-period-field"
            onChange={(event) => onPeriodChange(event.target.value)}
            value={currentPeriod ? currentPeriod.id : ''}
          >
            {supplyPeriods
              .filter((period) => currentPeriod && currentPeriod.owner === period.owner)
              .map((period) => {
                const dateFormat = 'd MMM yyyy';
                const from = format(new Date(period.startDate), dateFormat);
                const to = period.endDate ? format(new Date(period.endDate), dateFormat) : null;

                return (
                  <option key={period.id} value={period.id}>
                    {from} - {to}
                  </option>
                );
              })}
          </select>
        </>
      )}
    </div>
  );
};

export default SupplyAgreementComponent;
